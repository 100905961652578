/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  customer_sucess_report: { isDataLoaded: false },
  blog_listing: { isDataLoaded: false },
  press_releases: { isDataLoaded: false },
  common_software_category: [],
  common_services_category: [],
  common_research_data: [],
  common_count: {},
  common_seo_schema: {},
  common_tracking: {}
};

const saveCommonWebSiteData = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_WEBSITE_COMMON_DATA:
      return saveCommonWebSiteData(state, action.payload);
    case actionTypes.SET_COMPANY_DATA_CONTACTUS:
      return { ...state, ...action.payload };
    case actionTypes.SET_COMPANY_TESTIMONIAL:
      return { ...state, ...action.payload };
    case actionTypes.SET_COMPANY_INIT_PRESS_RELEASES:
      const pressReleaseData = {
        ...state.press_releases,
        ...action.payload,
        isDataLoaded: true
      };
      return { ...state, press_releases: pressReleaseData };
    case actionTypes.SET_COMPANY_MORE_PRESS_RELEASES:
      //Will only receive array
      const updatedPosts = [...state.press_releases.posts, ...action.payload];
      const pressReleaseUpdated = {
        ...state.press_releases,
        posts: updatedPosts
      };
      return { ...state, press_releases: pressReleaseUpdated };

    case actionTypes.SET_VENDOR_SOLUTION_DATA:
      return { ...state, vendor_solution: action.payload };
    case actionTypes.SET_CSR_DATA:
      const dataObject = {
        ...state.customer_sucess_report,
        ...action.payload,
        isDataLoaded: true
      };
      return { ...state, customer_sucess_report: dataObject };
    case actionTypes.UPDATE_CSR_PAGESTATS:
      const pageStatData = {
        ...state.customer_sucess_report,
        ...action.payload
      };
      return { ...state, customer_sucess_report: pageStatData };
    case actionTypes.SET_BLOGLIST_DATA:
      const blogDataObject = {
        ...state.blog_listing,
        ...action.payload,
        isDataLoaded: true
      };
      return { ...state, blog_listing: blogDataObject };
    case actionTypes.UPDATE_BLOGLIST_PAGESTATS:
      const pageStatBlogListingData = {
        ...state.blog_listing,
        ...action.payload
      };
      return { ...state, blog_listing: pageStatBlogListingData };
    case actionTypes.SET_SW_CAT_DATA:
      return { ...state, common_software_category: [...action.payload] };
    case actionTypes.SET_SW_SERV_DATA:
      return { ...state, common_services_category: [...action.payload] };
    case actionTypes.SET_SW_RESEARCH_DATA:
      return { ...state, common_research_data: [...action.payload] };
    case actionTypes.SET_SEO_DATA:
      return { ...state, common_seo_schema: { ...action.payload } };
    case actionTypes.SET_TRACKING_STATUS:
      return { ...state, common_tracking: { ...action.payload } };
    case actionTypes.SET_MISC_COUNT_DATA:
      const miscCount = {
        ...state.common_count,
        ...action.payload
      };
      return { ...state, common_count: { ...miscCount } };
    case actionTypes.SET_TOTAL_CUSTOMER_DATA:
      const ttlCustCount = {
        ...state.common_count,
        ...action.payload
      };
      return { ...state, common_count: { ...ttlCustCount } };
    default:
      return state;
  }
};

export default reducer;
