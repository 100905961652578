/**
 * Home Page Reducer
 */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  home_featured_video: [],
  home_trusted_by: [],
  home_pop_sw: [],
  home_pop_serv: [],
  home_discover_comp: [],
  home_featured_testimonial: {},
  home_featured_casestudy: {},
  home_text: {},
  home_featured_refernce: [],
  isEnterpriseLogin: false,
};

const saveHomeCommonData = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_HOMEPAGE_DATA:
      return saveHomeCommonData(state, action.payload);
    case actionTypes.SET_HOME_F_VIDEOS:
      return { ...state, home_featured_video: [...action.payload] };
    case actionTypes.SET_HOME_TRUSTEDBY:
      return { ...state, home_trusted_by: [...action.payload] };
    case actionTypes.SET_HOME_POP_SW:
      return { ...state, home_pop_sw: [...action.payload] };
    case actionTypes.SET_HOME_POP_SERV:
      return { ...state, home_pop_serv: [...action.payload] };
    case actionTypes.SET_HOME_DISCOVER_COMP:
      return { ...state, home_discover_comp: [...action.payload] };
    case actionTypes.SET_HOME_F_TESTIMONIAL:
      return { ...state, home_featured_testimonial: { ...action.payload } };
    case actionTypes.SET_HOME_F_CASESTUDY:
      return { ...state, home_featured_casestudy: { ...action.payload } };
    case actionTypes.SET_HOME_TEXT:
      return { ...state, home_metedata: { ...action.payload } };
    case actionTypes.ENTRERPRISE_LOGIN:
      return { ...state, isEnterpriseLogin: true };
    case actionTypes.ENTRERPRISE_LOGOUT:
      return { ...state, isEnterpriseLogin: false };
    case actionTypes.SAVE_HOMEPAGE_FEATURED_REFERENCE:
      return { ...state, home_featured_refernce: [...action.payload] };
    default:
      return state;
  }
};

export default reducer;
