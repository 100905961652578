/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isDataLoaded: false
};

const saveVendorDirectoryData = (state, payload) => {
  return {
    ...state,
    ...payload,
    isDataLoaded: true
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VENDOR_DIR_DATA:
      return saveVendorDirectoryData(state, action.payload);
    case actionTypes.SET_VENDOR_DIR_DATA_LOADED_FALSE:
      return { ...state, isDataLoaded: false };
    case actionTypes.SET_VENDOR_DIR_DATA_LOADED_TRUE:
      return { ...state, isDataLoaded: true };
    case actionTypes.RESET_VENDOR_DIR_DATA:
      return { isDataLoaded: false };
    case actionTypes.UPDATE_VENDOR_DIR_RESULTS:
      return {
        ...state,
        results: action.payload
      };
    case actionTypes.UPDATE_VENDOR_DIR_PAGESTATS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default reducer;
