/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  addYourCompany: false,
  claimYourProfile: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CLAIM_PROFILE_TRUE:
      return { claimYourProfile: true };
    case actionTypes.UPDATE_CLAIM_PROFILE_FALSE:
      return { claimYourProfile: false };
    case actionTypes.UPDATE_ADD_COMPANY_TRUE:
      return { addYourCompany: true };
    case actionTypes.UPDATE_ADD_COMPANY_FALSE:
      return { addYourCompany: false };
    default:
      return state;
  }
};

export default reducer;
