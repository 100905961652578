export const SAMPLE_ACTION = "SAMPLE_ACTION";
export const SAVE_WEBSITE_COMMON_DATA = "SAVE_WEBSITE_COMMON_DATA";
export const SAVE_HOMEPAGE_DATA = "SAVE_HOMEPAGE_DATA";
export const SAVE_HOMEPAGE_FEATURED_REFERENCE =
  "SAVE_HOMEPAGE_FEATURED_REFERENCE";
export const SAVE_VENDOR_COMMON_DATA = "SAVE_VENDOR_COMMON_DATA";
export const SET_VENDOR_COMMON_DATA_SLUG = "SET_VENDOR_COMMON_DATA_SLUG";
export const SAVE_VENDOR_PROFILE_DATA = "SAVE_VENDOR_PROFILE_DATA";
export const UPDATE_VENDOR_PROFILE_LEAD = "UPDATE_VENDOR_PROFILE_LEAD";
export const SAVE_VENDOR_TESTIMONIAL_DATA = "SAVE_VENDOR_TESTIMONIAL_DATA";
export const SAVE_VENDOR_TESTIMONIAL_FILTER = "SAVE_VENDOR_TESTIMONIAL_FILTER";
export const SAVE_VENDOR_RESET_FILTER = "SAVE_VENDOR_RESET_FILTER";
export const SAVE_VENDOR_CASESTUDIES_DATA = "SAVE_VENDOR_CASESTUDIES_DATA";
export const SAVE_VENDOR_CASESTUDIES_FILTER = "SAVE_VENDOR_CASESTUDIES_FILTER";
export const SAVE_VENDOR_VIDEOS_DATA = "SAVE_VENDOR_VIDEOS_DATA";
export const SAVE_VENDOR_VIDEOS_FILTER = "SAVE_VENDOR_VIDEOS_DATA";
export const SAVE_VENDOR_FAQ_DATA = "SAVE_VENDOR_FAQ_DATA";
export const SAVE_VENDOR_CUSTOMERS_DATA = "SAVE_VENDOR_CUSTOMERS_DATA";
export const UPDATE_VENDOR_TESTIMONIAL_REVIEWS =
  "UPDATE_VENDOR_TESTIMONIAL_REVIEWS";
export const UPDATE_VENDOR_CASESTUDIES_REVIEWS =
  "UPDATE_VENDOR_CASESTUDIES_REVIEWS";
export const UPDATE_VENDOR_VIDEOS_REVIEWS = "UPDATE_VENDOR_VIDEOS_REVIEWS";
export const SET_VENDOR_DATA_LOADED_TRUE = "SET_VENDOR_DATA_LOADED_TRUE";
export const SET_VENDOR_DATA_LOADED_FALSE = "SET_VENDOR_DATA_LOADED_FALSE";
export const RESET_VENDOR_COMMON_DATA = "RESET_VENDOR_COMMON_DATA";
export const RESET_VENDOR_PROFILE_DATA = "RESET_VENDOR_PROFILE_DATA";
export const RESET_VENDOR_TESTIMONIAL_DATA = "RESET_VENDOR_TESTIMONIAL_DATA";
export const RESET_VENDOR_TESTIMONIAL_REVIEWS =
  "RESET_VENDOR_TESTIMONIAL_REVIEWS";
export const RESET_VENDOR_CASESTUDIES_DATA = "RESET_VENDOR_CASESTUDIES_DATA";
export const RESET_VENDOR_VIDEOS_DATA = "RESET_VENDOR_VIDEOS_DATA";
export const RESET_VENDOR_CASESTUDIES_REVIEWS =
  "RESET_VENDOR_CASESTUDIES_REVIEWS";
export const RESET_VENDOR_VIDEOS_REVIEWS = "RESET_VENDOR_VIDEOS_REVIEWS";
export const SAVE_VENDOR_DIR_DATA = "SAVE_VENDOR_DIR_DATA";
export const UPDATE_VENDOR_DIR_PAGESTATS = "UPDATE_VENDOR_DIR_PAGESTATS";
export const SET_VENDOR_DIR_DATA_LOADED_FALSE =
  "SET_VENDOR_DIR_DATA_LOADED_FALSE";
export const RESET_VENDOR_DIR_DATA = "RESET_VENDOR_DIR_DATA";
export const SET_VENDOR_DIR_DATA_LOADED_TRUE =
  "SET_VENDOR_DIR_DATA_LOADED_TRUE";
export const UPDATE_VENDOR_DIR_RESULTS = "UPDATE_VENDOR_DIR_RESULTS";
export const UPDATE_CLAIM_PROFILE_TRUE = "UPDATE_CLAIM_PROFILE_TRUE";
export const UPDATE_CLAIM_PROFILE_FALSE = "UPDATE_CLAIM_PROFILE_FALSE";
export const UPDATE_ADD_COMPANY_TRUE = "UPDATE_ADD_COMPANY_TRUE";
export const UPDATE_ADD_COMPANY_FALSE = "UPDATE_ADD_COMPANY_FALSE";
export const SET_TESTIMONIAL_FILTER_TRUE = "SET_TESTIMONIAL_FILTER_TRUE";
export const SET_TESTIMONIAL_FILTER_FALSE = "SET_TESTIMONIAL_FILTER_FALSE";
export const SET_CASESTUDIES_FILTER_TRUE = "SET_CASESTUDIES_FILTER_TRUE";
export const SET_CASESTUDIES_FILTER_FALSE = "SET_CASESTUDIES_FILTER_FALSE";
export const SET_VIDEO_FILTER_TRUE = "SET_VIDEO_FILTER_TRUE";
export const SET_VIDEO_FILTER_FALSE = "SET_VIDEO_FILTER_FALSE";
export const SET_VIDEO_ACTIVE_POPUP = "SET_VIDEO_ACTIVE_POPUP";
export const RESET_VIDEO_ACTIVE_POPUP = "RESET_VIDEO_ACTIVE_POPUP";
export const UPDATE_LEADGEN_STATUS = "UPDATE_LEADGEN_STATUS";

export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_CATEGORY_DD_OPT = "SET_CATEGORY_DD_OPT";
export const ADDMORE_TO_CATEGORY_LIST = "ADDMORE_TO_CATEGORY_LIST";

export const RESET_CATEGORY_LIST = "RESET_CATEGORY_LIST";
export const SET_CATEGORY_DATALOADER_FALSE = "SET_CATEGORY_DATALOADER_FALSE";
export const UPDTAE_CATEGORY_RESULT = "UPDTAE_CATEGORY_RESULT";

export const SET_COMPANY_DATA_CONTACTUS = "SET_COMPANY_DATA_CONTACTUS";
export const SET_COMPANY_TESTIMONIAL = "SET_COMPANY_TESTIMONIAL";
export const SET_COMPANY_INIT_PRESS_RELEASES =
  "SET_COMPANY_INIT_PRESS_RELEASES";
export const SET_COMPANY_MORE_PRESS_RELEASES =
  "SET_COMPANY_MORE_PRESS_RELEASES";
export const SET_VENDOR_SOLUTION_DATA = "SET_VENDOR_SOLUTION_DATA";

export const NUM_UNBLURRED_REVIEW_TESTIMONIAL =
  "NUM_UNBLURRED_REVIEW_TESTIMONIAL";

export const NUM_UNBLURRED_REVIEW_CASESTUDY = "NUM_UNBLURRED_REVIEW_CASESTUDY";

export const NUM_UNBLURRED_REVIEW_VIDEO = "NUM_UNBLURRED_REVIEW_VIDEO";

//CSR Action Types
export const SET_CSR_DATA = "SET_CSR_DATA";
export const UPDATE_CSR_DATA = "UPDATE_CSR_DATA";
export const UPDATE_CSR_PAGESTATS = "UPDATE_CSR_PAGESTATS";
//Blog Action Types
export const SET_BLOGLIST_DATA = "SET_BLOGLIST_DATA";
export const UPDATE_BLOGLIST_DATA = "UPDATE_BLOGLIST_DATA";
export const UPDATE_BLOGLIST_PAGESTATS = "UPDATE_BLOGLIST_PAGESTATS";
//Website Common data
export const SET_SW_CAT_DATA = "SET_SW_CAT_DATA";
export const SET_SW_SERV_DATA = "SET_SW_SERV_DATA";
export const SET_SW_RESEARCH_DATA = "SET_SW_RESEARCH_DATA";
export const SET_SEO_DATA = "SET_SEO_DATA";
export const SET_TOTAL_CUSTOMER_DATA = "SET_TOTAL_CUSTOMER_DATA";
export const SET_MISC_COUNT_DATA = "SET_MISC_COUNT_DATA";
export const SET_TRACKING_STATUS = "SET_TRACKING_STATUS";
//Website Home data
export const SET_HOME_F_VIDEOS = "SET_HOME_F_VIDEOS";
export const SET_HOME_F_TESTIMONIAL = "SET_HOME_F_TESTIMONIAL";
export const SET_HOME_F_CASESTUDY = "SET_HOME_F_CASESTUDY";
export const SET_HOME_TRUSTEDBY = "SET_HOME_TRUSTEDBY";
export const SET_HOME_POP_SW = "SET_HOME_POP_SW";
export const SET_HOME_POP_SERV = "SET_HOME_POP_SERV";
export const SET_HOME_DISCOVER_COMP = "SET_HOME_DISCOVER_COMP";
export const SET_HOME_TEXT = "SET_HOME_TEXT";
//Extranet Profile
export const SET_EXT_COMMON = "SET_EXT_COMMON";
export const SET_EXT_COMPANYINFO = "SET_EXT_COMPANYINFO";
export const SET_EXT_COMP_QA = "SET_EXT_COMP_QA";
export const SET_EXT_DD_OPT = "SET_EXT_DD_OPT";
export const UPDATE_TEXT_FIELD = "UPDATE_TEXT_FIELD";
export const DELETE_SPECIALITY = "DELETE_SPECIALITY";
export const DELETE_OTHER_CATEGORY = "DELETE_OTHER_CATEGORY";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_DATACHANGE_FALSE = "SET_DATACHANGE_FALSE";
export const SET_DATACHANGE_TRUE = "SET_DATACHANGE_TRUE";

//Extranet Content
export const SET_DATA_LOADED_TRUE = "SET_DATA_LOADED_TRUE";
export const SET_DATA_LOADED_FALSE = "SET_DATA_LOADED_FALSE";
export const SET_EXT_CONTENT_OPTIONS = "SET_EXT_CONTENT_OPTIONS";
export const SET_EXT_CONTENT_TESTIMONIALS = "SET_EXT_CONTENT_TESTIMONIALS";
export const SET_EXT_CONTENT_CASESTUDIES = "SET_EXT_CONTENT_CASESTUDIES";
export const SET_EXT_CONTENT_VIDEOS = "SET_EXT_CONTENT_VIDEOS";
export const SET_EXT_CONTENT_CUST = "SET_EXT_CONTENT_CUST";
export const SET_EXT_CONTENT_USERS = "SET_EXT_CONTENT_USERS";
export const SET_PREVIEW_TESTIMONIALS = "SET_PREVIEW_TESTIMONIALS";
export const SET_PREVIEW_CASESTUDIES = "SET_PREVIEW_CASESTUDIES";
export const SET_PREVIEW_VIDEOS = "SET_PREVIEW_VIDEOS";
export const SET_ALL_CONTENT = "SET_ALL_CONTENT";
export const REMOVE_ALL_CONTENT = "REMOVE_ALL_CONTENT";
export const REMOVE_ALL_CONTENT_BY_ID = "REMOVE_ALL_CONTENT_BY_ID";
export const SET_CONTENT_FILTER = "SET_CONTENT_FILTER";
export const ADD_NEW_COMPANY_DETAIL = "ADD_NEW_COMPANY_DETAIL";
export const SET_EDIT_STATUS = "SET_EDIT_STATUS";
export const SET_EDIT_CONTENT_DETAIL = "SET_EDIT_CONTENT_DETAIL";
export const RESET_EDIT_CONTENT = "RESET_EDIT_CONTENT";
export const UPDATE_CONTENT_POPUP_STATE = "UPDATE_CONTENT_POPUP_STATE";
export const REMOVE_FILTER_BY_ID = "REMOVE_FILTER_BY_ID";
export const REMOVE_FILTER_TEXT = "REMOVE_FILTER_TEXT";
export const UPDATE_FROM_LOCATION = "UPDATE_FROM_LOCATION";
export const RESET_FROM_LOCATION = "RESET_FROM_LOCATION";

//Extranet Awards and Reports
export const SET_EXT_CSR_LISTING = "SET_EXT_CSR_LISTING";
export const SET_EXT_CSR_PREVIEW = "SET_EXT_CSR_PREVIEW";

//Extranet Prospect Intelligence
export const SET_EXT_PI_OPTIONS = "SET_EXT_PI_OPTIONS";
export const SET_EXT_PI_NEW_LIST = "SET_EXT_PI_NEW_LIST";
export const SET_EXT_PI_PURCHASED = "SET_EXT_PI_PURCHASED";
export const SET_EXT_PURCHASE_SIGNAL = "SET_EXT_PURCHASE_SIGNAL";

//Extranet Dashboard
export const SET_EXT_DASH_PROFILE_VIEW = "SET_EXT_DASH_PROFILE_VIEW";
export const SET_EXT_DASH_PI = "SET_EXT_DASH_PI";
export const SET_EXT_DASH_CONTENT_VIEW = "SET_EXT_DASH_CONTENT_VIEW";
export const SET_EXT_DASH_CASE_STUDIES = "SET_EXT_DASH_CASE_STUDIES";
export const SET_EXT_DASH_TESTIMONIALS = "SET_EXT_DASH_TESTIMONIALS";
export const SET_EXT_DASH_VIDEOS = "SET_EXT_DASH_VIDEOS";
export const SET_EXT_DASH_AWARDS = "SET_EXT_DASH_AWARDS";
export const SET_DISMISS_STATUS = "SET_DISMISS_STATUS";
export const SET_PROFILE_TYPE = "SET_PROFILE_TYPE";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const LOGOUT_USER = "LOGOUT_USER";

//SUBSCRIPTION
export const SET_SUBSCRIPTION_DETAIL = "SET_SUBSCRIPTION_DETAIL";

//Enterprise Login
export const ENTRERPRISE_LOGIN = "ENTRERPRISE_LOGIN";
export const ENTRERPRISE_LOGOUT = "ENTRERPRISE_LOGOUT";
