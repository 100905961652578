/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';
import lodash from 'lodash';

const initialState = {
  isDataLoaded: false,
  isDataObjectUpdated: false,
  isAuthenticated: true,
  isVerifiedProfile: false,
  isUpgradeDismiss: false,
  dismissedFor: [],
  commonInfo: {},
  companyname: '',
  company: {},
  q_and_a: [],
  dropDownOptions: {},
  content_options: {},
  content_testimonials: {},
  content_case_studies: {},
  content_videos: {},
  content_all: [],
  content_filter: {
    searchedKeywordFilter: '',
    industry: [],
    contentType: [],
    size: [],
  },
  content_users: {},
  content_cust: {},
  content_add_edit: {
    isEdit: false,
    company_id: '',
    customer_id: '',
    contact: '',
  },
  content_popup_state: {
    addCompany: false,
    addPerson: false,
    addTestimonial: false,
    addVideo: false,
    addCase: false,
    editCompany: false,
    editPerson: false,
    editTestimonial: false,
    editVideo: false,
    editCase: false,
  },
  preview_testimonial: {},
  preview_casestudy: {},
  preview_video: {},
  csr_listing: {},
  csr_preview: {},
  pi_options: {},
  pi_new_listing: {},
  pi_purchased: {},
  pi_purchase_signal: {},
  dasboard_profile_view: {},
  dasboard_pi: {},
  dasboard_content_view: {},
  dasboard_case_studies: {},
  dasboard_testimonials: {},
  dasboard_videos: {},
  dasboard_awards: {},
  from_location: '',
  subscription_detail: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //Data Loaded Status
    case actionTypes.SET_DATA_LOADED_TRUE:
      return { ...state, isDataLoaded: true };
    case actionTypes.SET_DATACHANGE_TRUE:
      if (state.isDataObjectUpdated) {
        return { ...state };
      } else {
        return { ...state, isDataObjectUpdated: true };
      }

    //Profile Event

    case actionTypes.SET_DATACHANGE_FALSE:
      return { ...state, isDataObjectUpdated: false };
    case actionTypes.SET_EXT_COMMON:
      return { ...state, commonInfo: { ...action.payload } };
    case actionTypes.SET_EXT_COMPANYINFO:
      return {
        ...state,
        company: { ...action.payload.company },
        isDataObjectUpdated: false,
      };
    case actionTypes.SET_EXT_COMP_QA:
      return {
        ...state,
        q_and_a: [...action.payload],
        isDataObjectUpdated: false,
      };
    case actionTypes.SET_EXT_DD_OPT:
      return { ...state, dropDownOptions: { ...action.payload } };
    case actionTypes.UPDATE_TEXT_FIELD:
      if (action.objKey === 'company') {
        const slicedObj = { ...state[action.objKey] };
        slicedObj[action.valueToUpdate] =
          action.valueToUpdate === 'size' ? Number(action.value) : action.value;
        return {
          ...state,
          [action.objKey]: slicedObj,
          isDataObjectUpdated: action.showSaveBar,
        };
      } else if (action.objKey === 'q_and_a') {
        const QA = [...state[action.objKey]];
        QA[action.valueToUpdate.split('-')[0]].answer = action.value;
        return { ...state, [action.objKey]: QA, isDataObjectUpdated: true };
      } else {
        return state;
      }
    case actionTypes.SET_AUTH_STATUS:
      return { ...state, isAuthenticated: action.status };
    case actionTypes.DELETE_SPECIALITY:
      const tempSpecialities = [...state.company.specialties];
      const deletedSpecialityIndex = lodash.findIndex(tempSpecialities, {
        id: action.id,
      });
      const tempComp = { ...state.company };
      tempSpecialities.splice(deletedSpecialityIndex, 1);
      tempComp['specialties'] = tempSpecialities;
      return { ...state, company: { ...tempComp } };
    case actionTypes.DELETE_OTHER_CATEGORY:
      const tempOthCat = [...state.company.other_categories];

      const deletedOthCatIndex = lodash.findIndex(tempOthCat, {
        id: action.id,
      });

      const tempOthComp = { ...state.company };

      tempOthCat.splice(deletedOthCatIndex, 1);
      tempOthComp['other_categories'] = tempOthCat;

      return { ...state, company: { ...tempOthComp } };

    //Content Event
    case actionTypes.UPDATE_FROM_LOCATION:
      return { ...state, from_location: action.payload };
    case actionTypes.RESET_FROM_LOCATION:
      return { ...state, from_location: '' };
    case actionTypes.SET_EXT_CONTENT_OPTIONS:
      return { ...state, content_options: { ...action.payload } };
    case actionTypes.SET_EXT_CONTENT_TESTIMONIALS:
      return { ...state, content_testimonials: action.payload };
    case actionTypes.SET_EXT_CONTENT_CASESTUDIES:
      return { ...state, content_case_studies: action.payload };
    case actionTypes.SET_EXT_CONTENT_VIDEOS:
      return { ...state, content_videos: action.payload };
    case actionTypes.SET_EXT_CONTENT_CUST:
      return { ...state, content_cust: action.payload };
    case actionTypes.SET_EXT_CONTENT_USERS:
      return { ...state, content_users: action.payload };
    case actionTypes.SET_PREVIEW_TESTIMONIALS:
      return { ...state, preview_testimonial: action.payload };
    case actionTypes.SET_PREVIEW_CASESTUDIES:
      return { ...state, preview_casestudy: action.payload };
    case actionTypes.SET_PREVIEW_VIDEOS:
      return { ...state, preview_video: action.payload };
    case actionTypes.SET_ALL_CONTENT:
      const tempAllContent = [...action.payload];
      return { ...state, content_all: tempAllContent };
    case actionTypes.REMOVE_ALL_CONTENT:
      return { ...state, content_all: [] };
    case actionTypes.REMOVE_ALL_CONTENT_BY_ID:
      const allContents = [...state.content_all];
      const deletedAllContentsIndex = lodash.findIndex(allContents, {
        id: action.id,
        type: action.contentType,
      });
      allContents.splice(deletedAllContentsIndex, 1);
      return { ...state, content_all: allContents };

    case actionTypes.REMOVE_FILTER_BY_ID:
      const toFilter = [...state.content_filter[action.criteria]];
      const deletedFilterIndex = lodash.indexOf(toFilter, action.id);
      toFilter.splice(deletedFilterIndex, 1);
      return {
        ...state,
        content_filter: {
          ...state.content_filter,
          [action.criteria]: toFilter,
        },
      };

    case actionTypes.REMOVE_FILTER_TEXT:
      return {
        ...state,
        content_filter: {
          ...state.content_filter,
          searchedKeywordFilter: '',
        },
      };

    case actionTypes.ADD_NEW_COMPANY_DETAIL:
      const newCompDetail = { ...state['content_add_edit'], ...action.payload };
      return { ...state, content_add_edit: newCompDetail };
    case actionTypes.SET_EDIT_CONTENT_DETAIL:
      const editContentDetail = {
        ...state['content_add_edit'],
        ...action.payload,
      };
      return { ...state, content_add_edit: editContentDetail };
    case actionTypes.SET_EDIT_STATUS:
      const isEditStatus = {
        ...state['content_add_edit'],
        isEdit: action.status,
      };
      if (action.status) {
        return { ...state, content_add_edit: isEditStatus };
      } else {
        return { ...state, content_add_edit: { isEdit: false } };
      }
    case actionTypes.RESET_EDIT_CONTENT:
      return { ...state, content_add_edit: { isEdit: false } };
    case actionTypes.SET_CONTENT_FILTER:
      //need to work
      return {
        ...state,
        content_filter: { ...state['content_filter'], ...action.payload },
      };
    case actionTypes.UPDATE_CONTENT_POPUP_STATE:
      //need to work
      return {
        ...state,
        content_popup_state: {
          ...state['content_popup_state'],
          ...action.payload,
        },
      };

    //Extranet Awards and Reports
    case actionTypes.SET_EXT_CSR_LISTING:
      return { ...state, csr_listing: { ...action.payload } };
    case actionTypes.SET_EXT_CSR_PREVIEW:
      return { ...state, csr_preview: { ...action.payload } };
    //Extranet Prospect Intelligence
    case actionTypes.SET_EXT_PI_OPTIONS:
      return { ...state, pi_options: { ...action.payload } };
    case actionTypes.SET_EXT_PI_NEW_LIST:
      return { ...state, pi_new_listing: { ...action.payload } };
    case actionTypes.SET_EXT_PI_PURCHASED:
      return { ...state, pi_purchased: { ...action.payload } };
    case actionTypes.SET_EXT_PURCHASE_SIGNAL:
      return { ...state, pi_purchase_signal: { ...action.payload } };

    //Extranet Dashboard
    case actionTypes.SET_EXT_DASH_PROFILE_VIEW:
      return { ...state, dasboard_profile_view: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_PI:
      return { ...state, dasboard_pi: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_CONTENT_VIEW:
      return { ...state, dasboard_content_view: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_CASE_STUDIES:
      return { ...state, dasboard_case_studies: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_TESTIMONIALS:
      return { ...state, dasboard_testimonials: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_VIDEOS:
      return { ...state, dasboard_videos: { ...action.payload } };
    case actionTypes.SET_EXT_DASH_AWARDS:
      return { ...state, dasboard_awards: { ...action.payload } };
    case actionTypes.SET_DISMISS_STATUS:
      return {
        ...state,
        isUpgradeDismiss: action.status,
        dismissedFor: [...state.dismissedFor, action.company],
      };
    case actionTypes.SET_PROFILE_TYPE:
      return { ...state, isVerifiedProfile: action.status };
    case actionTypes.LOGOUT_USER:
      return initialState;
    case actionTypes.SET_COMPANY_NAME:
      return { ...state, companyname: action.companyname };

    case actionTypes.SET_SUBSCRIPTION_DETAIL:
      return {
        ...state,
        subscription_detail: action.subsDetail,
      };
    default:
      return state;
  }
};

export default reducer;
