/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const saveVendorProfileData = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VENDOR_PROFILE_DATA:
      return saveVendorProfileData(state, action.payload);
    case actionTypes.RESET_VENDOR_PROFILE_DATA:
      return {};
    case actionTypes.UPDATE_LEADGEN_STATUS:
      return { ...state, is_lead_generate_popup: action.status };
    default:
      return state;
  }
};

export default reducer;
