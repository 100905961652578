/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isDataLoaded: false,
  vendorCustomers: {},
  vendorFAQ: {},
  vendorFilter: ''
};

const saveVendorCommonData = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VENDOR_COMMON_DATA:
      return saveVendorCommonData(state, action.payload);
    case actionTypes.SET_VENDOR_DATA_LOADED_TRUE:
      return { ...state, isDataLoaded: true };
    case actionTypes.SET_VENDOR_DATA_LOADED_FALSE:
      return { ...state, isDataLoaded: false };
    case actionTypes.RESET_VENDOR_COMMON_DATA:
      return { isDataLoaded: false };
    case actionTypes.SET_VENDOR_COMMON_DATA_SLUG:
      return { ...state, slug: action.payload };
    case actionTypes.SAVE_VENDOR_CUSTOMERS_DATA:
      return { ...state, vendorCustomers: action.payload };
    case actionTypes.SAVE_VENDOR_FAQ_DATA:
      return { ...state, vendorFAQ: action.payload };
    case actionTypes.SAVE_VENDOR_TESTIMONIAL_FILTER:
      return { ...state, vendorFilter: action.payload };
    case actionTypes.SAVE_VENDOR_RESET_FILTER:
      return { ...state, vendorFilter: '' };

    default:
      return state;
  }
};

export default reducer;
