import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { CookiesProvider } from "react-cookie";
import thunk from "redux-thunk";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import homePageReducer from "./store/reducers/homePageReducer";
import websiteCommonReducer from "./store/reducers/websiteCommonReducer";
import vendorCommonReducer from "./store/reducers/vendorCommonReducer";
import vendorProfileReducer from "./store/reducers/vendorProfileReducer";
import vendorTestimonialsReducer from "./store/reducers/vendorTestimonialsReducer";
import vendorCaseStudiesReducer from "./store/reducers/vendorCaseStudiesReducer";
import vendorVideosReducer from "./store/reducers/vendorVideosReducer";
import vendorDirectoryReducer from "./store/reducers/vendorDirectoryReducer";
import vendorCategoryReducer from "./store/reducers/vendorCategoryReducer";
import popupState from "./store/reducers/popUpState";
import extranetProfile from "./store/reducers/extranetProfile";

require("dotenv").config();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  homePage: homePageReducer,
  webSiteCommonData: websiteCommonReducer,
  vendorCommon: vendorCommonReducer,
  vendorProfile: vendorProfileReducer,
  vendorTestimonials: vendorTestimonialsReducer,
  vendorCaseStudies: vendorCaseStudiesReducer,
  vendorVideos: vendorVideosReducer,
  vendorDirectory: vendorDirectoryReducer,
  popupState: popupState,
  vendorCategory: vendorCategoryReducer,
  extranetProfile: extranetProfile,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <CookiesProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
