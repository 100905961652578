/**
 * Sample Reducer
 */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isDataLoaded: false,
  dropdown_option: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORY_LIST:
      return { ...state, ...action.payload, isDataLoaded: true };
    case actionTypes.ADDMORE_TO_CATEGORY_LIST:
      const oldResult = [...state['results']];
      const newResult = [...action.payload.results];
      return {
        ...state,
        results: [...oldResult, ...newResult],
        isDataLoaded: true,
      };
    case actionTypes.RESET_CATEGORY_LIST:
      return { ...initialState };
    case actionTypes.SET_CATEGORY_DATALOADER_FALSE:
      return { ...state, isDataLoaded: false };
    case actionTypes.UPDTAE_CATEGORY_RESULT:
      return { ...state, results: action.payload };
    case actionTypes.SET_CATEGORY_DD_OPT:
      return { ...state, dropdown_option: action.payload };
    default:
      return state;
  }
};

export default reducer;
