import axios from "axios";
import { Cookies } from "react-cookie";
import { openAPI } from "../../config";

export const getReviewsToShow = (allReviews, noOfResultPage) => {
  if (noOfResultPage === 15 || noOfResultPage === 30) {
    let limited = allReviews.slice(0, noOfResultPage);
    return limited;
  } else {
    return allReviews;
  }
};

export const getCookie = name => {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

//Only for csrftoken
export const setCookies = (name, value) => {
  let newCookies = new Cookies();
  newCookies.set(name, value, {
    path: "/",
  });
};

export const getVendorCommon = vendorId => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/common`,
    {
      withCredentials: true,
    }
  );
};

export const getVendorProfile = vendorId => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/profile`,
    {
      withCredentials: true,
    }
  );
};

export const getVendorTestimonials = (
  vendorId,
  isAll,
  isTestimonialTabs,
  searchParams
) => {
  if (isAll && isTestimonialTabs) {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/testimonials?view=all`,
      {
        withCredentials: true,
      }
    );
  } else if (!!searchParams) {
    return openAPI.get(
      `${
        process.env.REACT_APP_API_URL
      }/vendor/${vendorId}/testimonials${searchParams
        .replace(/&+/g, "&")
        .replace(/&+$/, "")
        .replace(/\?&/, "?")}`,
      {
        withCredentials: true,
      }
    );
  } else {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/testimonials`,
      {
        withCredentials: true,
      }
    );
  }
};

export const getVendorCaseStudies = (
  vendorId,
  isAll,
  isCaseStudiesTab,
  searchParams
) => {
  if (isAll && isCaseStudiesTab) {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/case-studies?view=all`,
      {
        withCredentials: true,
      }
    );
  } else if (!!searchParams) {
    return openAPI.get(
      `${
        process.env.REACT_APP_API_URL
      }/vendor/${vendorId}/case-studies${searchParams
        .replace(/&+/g, "&")
        .replace(/&+$/, "")
        .replace(/\?&/, "?")}`,
      {
        withCredentials: true,
      }
    );
  } else {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/case-studies`,
      {
        withCredentials: true,
      }
    );
  }
};

export const getVendorVideos = (vendorId, isAll, isVideoTabs, searchParams) => {
  if (isAll && isVideoTabs) {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/customer-videos?view=all`,
      {
        withCredentials: true,
      }
    );
  } else if (!!searchParams) {
    return openAPI.get(
      `${
        process.env.REACT_APP_API_URL
      }/vendor/${vendorId}/customer-videos${searchParams
        .replace(/&+/g, "&")
        .replace(/&+$/, "")
        .replace(/\?&/, "?")}`,
      {
        withCredentials: true,
      }
    );
  } else {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/customer-videos`,
      {
        withCredentials: true,
      }
    );
  }
};

export const getVendorCustomers = vendorId => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/customers`,
    {
      withCredentials: true,
    }
  );
};

export const getVendorFAQ = vendorId => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/vendor/${vendorId}/faq`,
    {
      withCredentials: true,
    }
  );
};

export const increaseViewCount = (apiURL, onTab, reviewId) => {
  openAPI
    .put(
      `${apiURL}/log/${onTab}/${reviewId}`,
      {},
      {
        withCredentials: true,
        headers: {
          "X-CSRFToken": `${getCookie("fc_react_csrfmiddlewaretoken")}`,
        },
      }
    )
    .then(response => {})
    .catch(error => {});
};

export const getVendorDirectory = qParam => {
  if (Boolean(qParam)) {
    return openAPI.get(`${process.env.REACT_APP_API_URL}/vendors${qParam}`, {
      withCredentials: true,
    });
  } else {
    return openAPI.get(`${process.env.REACT_APP_API_URL}/vendors`, {
      withCredentials: true,
    });
  }
};

export const getVendorDirectoryByAlphabet = alphabet => {
  if (alphabet === "22") {
    return openAPI.get(`${process.env.REACT_APP_API_URL}/vendors/%23`, {
      withCredentials: true,
    });
  } else {
    return openAPI.get(
      `${process.env.REACT_APP_API_URL}/vendors/${alphabet.toString()}`,
      {
        withCredentials: true,
      }
    );
  }
};

export const getSoftwareCategoryList = domain => {
  return openAPI.get(`${process.env.REACT_APP_API_URL}/software/${domain}`, {
    withCredentials: true,
  });
};

export const getServicesCategoryList = domain => {
  return openAPI.get(`${process.env.REACT_APP_API_URL}/services/${domain}`, {
    withCredentials: true,
  });
};

export const getSoftwareCategoryListByParam = (domain, sortParam) => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/software/${domain}?sort=${sortParam}`,
    {
      withCredentials: true,
    }
  );
};

export const getServicesCategoryListByParam = (domain, sortParam) => {
  return openAPI.get(
    `${process.env.REACT_APP_API_URL}/services/${domain}?sort=${sortParam}`,
    {
      withCredentials: true,
    }
  );
};

export const popupHandler = (valueToSet, uniqueClass) => {
  const overlayOpen = valueToSet;
  const body = document.body;
  const selector = `.overlay_popup.${uniqueClass}`;
  console.log("Pop UP selector :: ", selector);

  const overlay = document.querySelector(selector);
  console.log("Overlay :: ", overlay);
  /* Toggle the aria-hidden state on the overlay and the 
          no-scroll class on the body */
  if (overlay) {
    overlay.setAttribute("aria-hidden", !overlayOpen);
    body.classList.toggle("noscroll", overlayOpen);
    /* On some mobile browser when the overlay was previously
          opened and scrolled, if you open it again it doesn't 
          reset its scrollTop property after the fadeout */
    setTimeout(function () {
      overlay.scrollTop = 0;
    }, 1000);
  }
};

const partialStarPercentage = partialStar => {
  if (parseInt(partialStar) === 1) {
    return { percent: "ten" };
  }
  if (parseInt(partialStar) === 2) {
    return { percent: "twenty" };
  }
  if (parseInt(partialStar) === 3) {
    return { percent: "thirty" };
  }
  if (parseInt(partialStar) === 4) {
    return { percent: "forty" };
  }
  if (parseInt(partialStar) === 5) {
    return { percent: "fifty" };
  }
  if (parseInt(partialStar) === 6) {
    return { percent: "sixty" };
  }
  if (parseInt(partialStar) === 7) {
    return { percent: "seventy" };
  }
  if (parseInt(partialStar) === 8) {
    return { percent: "eighty" };
  }
  if (parseInt(partialStar) === 9) {
    return { percent: "ninty" };
  }
  if (parseInt(partialStar) === 10) {
    return { percent: "hundred" };
  }
};

export const composeArrayForRatings = rating => {
  if (rating) {
    const fixedRating = rating.toFixed(1);
    let filledStar = [];
    const blankStar = Math.floor(5 - fixedRating);
    const blankStarArr = [];
    for (let b = 0; b < blankStar; b++) {
      blankStarArr.push({ percent: "zero" });
    }

    const splitedRating = `${fixedRating}`.split(".");

    const tempArr = [];
    if (splitedRating.length === 2) {
      for (let a = 0; a < splitedRating[0]; a++) {
        tempArr.push({ percent: "hundred" });
      }
      tempArr.push(partialStarPercentage(splitedRating[1]));
      filledStar = [...tempArr];
    } else {
      for (let a = 0; a < splitedRating[0]; a++) {
        tempArr.push({ percent: "hundred" });
      }
      filledStar = [...tempArr];
    }
    return [...filledStar, ...blankStarArr];
  } else {
    return [];
  }
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
