import React, { useEffect } from "react";

const Error404 = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
};

export default Error404;
