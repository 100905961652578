import axios from 'axios';
import { getCookie, setCookies } from './components/Common/CommonMethods';

export const authorisedAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

authorisedAPI.interceptors.request.use((request) => {
  const authToken = getCookie('fc_react_auth_token');
  if (authToken) {
    request.headers.common['Authorization'] = `Token ${authToken}`;
  }
  let httpVerb = ['post', 'put', 'delete'];
  if (httpVerb.indexOf(request.method.toLowerCase()) !== -1) {
    const csrftoken = getCookie('fc_react_csrfmiddlewaretoken');
    if (csrftoken) {
      request.headers.common['X-CSRFToken'] = csrftoken;
    }
    return request;
  }
  return request;
});

authorisedAPI.interceptors.response.use((response) => {
  return response;
});

export const openAPI = axios.create();

openAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/privacy`, {
          withCredentials: true,
        })
        .then((localresponse) => {
          if (localresponse && localresponse.headers) {
            setCookies(
              'fc_react_csrfmiddlewaretoken',
              localresponse.headers['x-csrftoken']
            );
          }
          let latestCSRFToken = !!getCookie('fc_react_csrfmiddlewaretoken')
            ? getCookie('fc_react_csrfmiddlewaretoken')
            : getCookie('csrftoken');

          axios({
            method: `${error.response.config.method}`,
            url: `${error.response.config.url}`,
            withCredentials: true,
            headers: {
              'X-CSRFToken': `${latestCSRFToken}`,
            },
          });
        });
    }
    return Promise.reject(error);
  }
);
