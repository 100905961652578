/**
 * Sample Reducer
 */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activeVideoPopUp: {
    isPopUpActive: false,
  },
};

const saveVendorVideosData = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const updateReview = (state, payload) => {
  const localState = { ...state };
  return {
    ...localState,
    reviews: payload.reviews,
    pagination: payload.pagination,
    meta_noindex: payload.meta_noindex,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VENDOR_VIDEOS_DATA:
      return saveVendorVideosData(state, action.payload);
    case actionTypes.UPDATE_VENDOR_VIDEOS_REVIEWS:
      return updateReview(state, action.payload);
    case actionTypes.RESET_VENDOR_VIDEOS_DATA:
      return {};
    case actionTypes.RESET_VENDOR_VIDEOS_REVIEWS:
      return { ...state, reviews: [] };
    case actionTypes.SET_VIDEO_FILTER_TRUE:
      return { ...state, filterData: true };
    case actionTypes.SET_VIDEO_FILTER_FALSE:
      return { ...state, filterData: false };
    case actionTypes.SET_VIDEO_ACTIVE_POPUP:
      return { ...state, activeVideoPopUp: action.payload };
    case actionTypes.RESET_VIDEO_ACTIVE_POPUP:
      return { ...state, activeVideoPopUp: "" };
    case actionTypes.NUM_UNBLURRED_REVIEW_VIDEO:
      return { ...state, num_unblurred_reviews: -1 };
    default:
      return state;
  }
};

export default reducer;
