/**
 * Sample Reducer
 */
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  filterData: false,
};

const saveVendorCaseStudiesData = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const updateReview = (state, payload) => {
  const localState = { ...state };
  return {
    ...localState,
    reviews: payload.reviews,
    pagination: payload.pagination,
    meta_noindex: payload.meta_noindex,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VENDOR_CASESTUDIES_DATA:
      return saveVendorCaseStudiesData(state, action.payload);
    case actionTypes.UPDATE_VENDOR_CASESTUDIES_REVIEWS:
      return updateReview(state, action.payload);
    case actionTypes.RESET_VENDOR_CASESTUDIES_DATA:
      return {};
    case actionTypes.RESET_VENDOR_CASESTUDIES_REVIEWS:
      return { ...state, reviews: [] };
    case actionTypes.SET_CASESTUDIES_FILTER_TRUE:
      return { ...state, filterData: true };
    case actionTypes.SET_CASESTUDIES_FILTER_FALSE:
      return { ...state, filterData: false };
    case actionTypes.NUM_UNBLURRED_REVIEW_CASESTUDY:
      return { ...state, num_unblurred_reviews: -1 };
    default:
      return state;
  }
};

export default reducer;
